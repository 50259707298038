<template>
    <div>
        <page-title>
            {{ entity }}<span v-if="id">#{{ id }}</span>
        </page-title>
        <audit-history :id="id" :entity="entity"></audit-history>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import AuditHistory from '@/components/pages/audit/History'

export default {
    components: {
        PageTitle,
        AuditHistory,
    },

    props: {
        entity: {
            type: String,
            default: null,
        },

        id: {
            type: [Number, String],
            default: null,
        },
    },
}
</script>
