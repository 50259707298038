<template>
    <div>
        <page-title>{{ $t('menu.audit') }}</page-title>
        <audit-list></audit-list>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import AuditList from '@/components/pages/audit/List'

export default {
    components: {
        PageTitle,
        AuditList,
    },
}
</script>
