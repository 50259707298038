<template>
    <div>
        <overlay :show="busy">
            <v-data-table
                :items="items"
                :headers="headers"
                class="data-table border-g-e8"
                disable-filtering
                disable-pagination
                hide-default-footer
            >
                <template v-slot:[`item.entity`]="{ item }">
                    <router-link :to="getItemLink(item)">
                        {{ item.entity }}
                    </router-link>
                </template>
                <template v-slot:[`item._rowActions`]="{ item }">
                    <div class="row-action">
                        <div :class="['edit-button', 'd-inline-block']">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        text
                                        target
                                        icon
                                        :to="getItemLink(item)"
                                        v-on="on"
                                    >
                                        <v-icon>list_alt</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('actions.history') }}</span>
                            </v-tooltip>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </overlay>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import AuditLogApiClient from '@/api/RestApi/AuditLogApiClient'
import { nsParam } from '@/services/namespaceParam'
import Overlay from '@/components/overlay/Overlay'

export default {
    components: {
        Overlay,
    },

    data() {
        return {
            items: [],
            busy: true,
        }
    },

    computed: {
        headers() {
            return [
                {
                    value: 'entity',
                    text: this.$t('audit.entity'),
                    sortable: false,
                },
                {
                    value: 'table',
                    text: this.$t('audit.table'),
                    sortable: false,
                },
                {
                    value: 'operations',
                    text: this.$t('audit.operations'),
                    sortable: false,
                },
                {
                    value: '_rowActions',
                    text: '',
                    class: 'row-actions-header',
                    sortable: false,
                },
            ]
        },
    },

    async mounted() {
        const items = await AuditLogApiClient.list()
        this.items = items
        this.busy = false
    },

    methods: {
        getItemLink(item) {
            return {
                name: routeType.AUDIT_LOG_HISTORY,
                params: { entity: nsParam(item.entity) },
            }
        },
    },
}
</script>
