export function getAction(item) {
    switch (item.type) {
        case 'insert':
            return 'inserted'
        case 'update':
            return 'updated'
        case 'remove':
            return 'deleted'
        case 'associate':
            return 'associated'
        case 'dissociate':
            return 'dissociated'
        default:
            return '???'
    }
}

export function getSource(item) {
    switch (item.type) {
        case 'remove':
            return item.diffs
        case 'associate':
        case 'dissociate':
            return item.diffs.source
        default:
            return null
    }
}

export function getTarget(item) {
    switch (item.type) {
        case 'associate':
        case 'dissociate':
            return item.diffs.target
        default:
            return null
    }
}

export function getDirection(item) {
    switch (item.type) {
        case 'associate':
            return 'to'
        case 'dissociate':
            return 'from'
        default:
            return null
    }
}

export function transformHistoryItem(item) {
    item.action = getAction(item)
    item.source = getSource(item)
    item.target = getTarget(item)
    item.direction = getDirection(item)
    return item
}
